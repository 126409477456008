<template>
  <v-form ref="formElem">
    <translation-locales />
    <v-card>
      <v-card-text>
        <v-row>
          <v-col :cols="cols">
            <h4>Quota Rule</h4>

            <v-text-field
              v-model="form.interval_count"
              type="number"
              label="Expiry Days"
              hint="When purchased, the quota add N days to expire (-1 for no expirey)"
            />

            <v-text-field
              v-model="form.interval_unit"
              disabled
              label="Expiry Unit (default Days)"
            />

            <v-text-field
              v-model="form.quota"
              type="number"
              label="Quota"
              hint="When purchased, the user gets N quotas. 0=wont generate a user_quota object."
            />

            <translatable-editor
              v-model="form.application_remarks"
              label="Remarks when user tries to apply"
            />

            <v-text-field
              v-model="form.sort"
              type="number"
              label="Sort (ASC)"
              hint="SKUs after purchase are sorted in this ASC order."
            />

            <div class="d-flex">
              <v-text-field
                v-model="searchQuery"
                class="mr-4"
                label="search SKU name (en)"
                style="max-width: 200px"
              >
              </v-text-field>

              <v-autocomplete
                v-model="form.rechargeable_id"
                :items="skuOptions"
                item-text="title"
                item-value="value"
                label="Select a ORIGIN SKU as topup target and time slot origin."
              >
              </v-autocomplete>
            </div>

            <div class="d-flex">
              <v-text-field
                v-model="searchQuery2"
                class="mr-4"
                label="(Optional) search stackable SKU name (en)"
                style="max-width: 200px"
              >
              </v-text-field>

              <v-autocomplete
                v-model="form.stackable_id"
                :items="skuOptions2"
                item-text="title"
                item-value="value"
                label="Select a SKU that you'd like others be stackable to it"
                clearable
              >
              </v-autocomplete>
            </div>
            <p>Example:</p>
            <small>e.g. stacks 3/5/ 7 sessions into 3 sessions.</small><br>
            <small>Even if the user only buys 5+7 without 3, they are still stacked as 12 under the key of "3 sessions".</small><br>
            <small>must be under the same product.</small><br>
            <small>user must input the same participant.</small><br>

            <h4 class="mt-4">
              Quota's Application Rule
            </h4>

            <v-switch
              v-model="form.application_rule.require_slot"
              color="primary"
              :label="'Require Time Slot'"
              :messages="['Require the user to select a slot when apply.']"
            ></v-switch>

            <v-switch
              v-model="form.application_rule.require_destination"
              color="primary"
              :label="'Require Destination'"
              :messages="['Require the user to provide an exisint destintion when apply. (e.g. pick a branch or give user home address)']"
            ></v-switch>

            <template v-if="form.application_rule.require_destination">
              <v-select
                v-model="form.application_rule.destination_types"
                placeholder="Destination"
                :items="destinationOptions"
                item-text="title"
                item-value="value"
                clearable
                multiple
                chip
                class="mt-2"
              ></v-select>
            </template>

            <v-switch
              v-model="form.application_rule.amendment_cancel"
              color="primary"
              label="Can request cancellation even when confirmed"
            ></v-switch>

            <v-switch
              v-show="form.application_rule.amendment_cancel"
              v-model="form.application_rule.amendment_cancel_auto_accept"
              color="primary"
              label="Cancellation Amendment is auto-accepted"
            ></v-switch>

            <v-text-field
              v-show="form.application_rule.amendment_cancel"
              v-model="form.application_rule.amendment_last_hours"
              type="number"
              class="mt-4"
              label="Can request cancel at least (X) hours before the activity starts."
              hint="small number (<=48), user can cancel at very late. large number (>99) user must request cancel very early."
            />

            <v-text-field
              v-model="form.application_rule.auto_complete_strategy"
              class="mt-4"
              label="Auto Complete Strategy. AXA=day_end, auto completes at the slot's day end."
              disabled
            />

            <v-text-field
              v-model="form.application_rule.auto_complete_hours"
              type="number"
              class="mt-4"
              :label="`Auto complete after N hours the time slot strategy: ${form.application_rule.auto_complete_strategy}, AXA=0=${form.application_rule.auto_complete_strategy} Day end + 0 hour`"
            />

            <v-text-field
              v-model="form.application_rule.min_application"
              type="number"
              class="mt-4"
              label="Min Application(s) required"
            />

            <v-text-field
              v-model="form.application_rule.max_application"
              type="number"
              label="Max Application(s) allowed"
            />

            <v-switch
              v-model="form.enable_recharge"
              color="primary"
              label="Enable Recharge"
              :messages="['Enable user to see recharge button when quota depleted and then go to re-top-up.']"
            ></v-switch>

            <v-switch
              v-if="!resource"
              v-model="form.associate"
              color="primary"
              :label="'Associate. If checked, the system use set this SKU to use this rule automatically.'"
            ></v-switch>

            <slot name="before-submit"></slot>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableEditor, TranslationLocales } from '@/components'
import { useTranslatable } from '@/composables'
import useSkuList from '@/modules/product/views/sku-list/useSkuList'
import { between, required } from '@core/utils/validation'
import { mdiCloseCircle } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  components: { TranslationLocales, TranslatableEditor },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Number,
      default: 6,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const field = (name, defValue) => ({
      [name]: props.resource ? props.resource[name] : defValue,
    })

    const ruleField = (name, defValue) => ({
      [name]: props.resource && props.resource.application_rule[name] !== undefined ? props.resource.application_rule[name] : defValue,
    })

    const initialForm = {
      ...field('interval_count', -1),
      ...field('interval_unit', 'day'),
      ...field('quota', 1),
      ...field('sort', 1),
      ...field('rechargeable_id', null),
      ...field('stackable_id', null),
      ...field('enable_recharge', true),
      application_remarks: useTranslatable(props.resource, 'application_remarks'),
      associate: false,
      application_rule: {
        ...ruleField('require_slot', true),
        ...ruleField('require_destination', true),
        ...ruleField('destination_types', []),
        ...ruleField('amendment_cancel', true),
        ...ruleField('amendment_cancel_auto_accept', false),
        ...ruleField('amendment_last_hours', 48),
        ...ruleField('auto_complete_strategy', 'day_end'),
        ...ruleField('auto_complete_hours', 24),
        ...ruleField('min_application', 1),
        ...ruleField('max_application', 2),
      },
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) {
        form.value.application_rule.amendment_last_hours = parseInt(form.value.application_rule.amendment_last_hours)
        form.value.application_rule.auto_complete_hours = parseInt(form.value.application_rule.auto_complete_hours)
        form.value.application_rule.min_application = parseInt(form.value.application_rule.min_application)
        form.value.application_rule.max_application = parseInt(form.value.application_rule.max_application)
        emit('submit', form.value)
      }
    }

    const destinationOptions = ref([
      { title: 'Home Address', value: 'user_address' },
      { title: 'Branch', value: 'branch' },
    ])

    const { searchQuery, skuOptions } = useSkuList()
    const { searchQuery: searchQuery2, skuOptions: skuOptions2 } = useSkuList()

    if (props.resource) {
      setTimeout(() => {
        searchQuery.value = props.resource.rechargeable?.name.en
        searchQuery2.value = props.resource.stackable?.name.en
      }, 50)
    }

    return {
      form,
      formElem,
      validate,
      between,
      required,

      destinationOptions,
      skuOptions,
      searchQuery,
      skuOptions2,
      searchQuery2,

      icons: {
        mdiCloseCircle,
      },
    }
  },
}
</script>
